import { v4 } from "uuid"
import {
  TiktokEvent,
  TikTokEventContents,
  TtqEventParameter,
  TikTokEventOptions,
  TikTokEventBody,
} from "@tential/ec-gql-schema/types/tiktok"
import { Sku } from "@tential/ec-gql-schema/models/sku"
import { ProductFragmentFragment, OrderDetailForThankyouFragment } from "~/types/type.generated"
import { useLocalUser } from "~/composables/useLocalUser"
import { useStore } from "~/stores"

/**
 * TikTok Pixelにイベントを送信
 * ベースコードはGTMのカスタムHTMLタグで設定済み
 * document: https://business-api.tiktok.com/portal/docs?id=1701890973258754
 */
const trackTtqEvent = (eventName: TiktokEvent, contents: TikTokEventContents[], eventId: string, url?: string) => {
  if (!window.ttq) return
  const ttqParameter: TtqEventParameter = {
    content_type: "product",
    contents,
    currency: "JPY",
    description: url ?? "",
  }
  const ttqOptions: TikTokEventOptions = {
    event_id: eventId,
  }
  window.ttq.track(eventName, ttqParameter, ttqOptions)
}

/**
 * TikTok Api Eventにイベントを送信
 * document: https://bytedance.sg.larkoffice.com/docx/doxcnAsvsQkPjcfz2yJKZ2QW6Xd
 */
const sendTikTokEvent = (
  externalId: string,
  eventId: string,
  event: TiktokEvent,
  contents: TikTokEventContents[],
  url?: string,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const body: TikTokEventBody = {
    event,
    event_id: eventId,
    user: {
      external_id: externalId,
    },
    properties: {
      content_type: "product",
      currency: "JPY",
      contents,
    },
    page: {
      url: url ?? "",
    },
  }

  // useFetch("/api/tiktok_event", {
  //   method: "POST",
  //   body,
  // }).catch((error) => {
  //   console.error("Error sending TikTok Event:", error)
  // })
}

const getParameterIds = () => {
  const store = useStore()
  const { getLocalUserId } = useLocalUser()
  const eventId = v4().toString().replace(/-/g, "")
  const userId = store.user.document_id
  const localUserId = getLocalUserId()
  const externalId = userId || localUserId || ""
  return { eventId, externalId }
}

/** TikTok Pixel カートに追加イベント */
export const trackTtqAddCart = (skus: Sku[]): void => {
  const event: TiktokEvent = "AddToCart"
  const { eventId, externalId } = getParameterIds()

  const contents = skus.map((sku) => ({
    content_id: sku.document_id,
    content_name: sku.name,
  }))

  sendTikTokEvent(externalId, eventId, event, contents)
  trackTtqEvent(event, contents, eventId)
}

// TikTok Pixel コンテンツの閲覧イベント
export const trackingTtqViewContent = (product?: ProductFragmentFragment, url?: string): void => {
  if (!process.client) return

  const event: TiktokEvent = "ViewContent"
  const { eventId, externalId } = getParameterIds()

  const contents = [
    {
      content_id: product?.document_id ?? "",
      content_name: product?.name ?? "",
    },
  ]

  sendTikTokEvent(externalId, eventId, event, contents, url)
  trackTtqEvent(event, contents, eventId, url)
}

// TikTok Pixel 支払い完了イベント
export const trackingTtqCompletePayment = (order?: Omit<OrderDetailForThankyouFragment, "_id"> | null): void => {
  const event: TiktokEvent = "CompletePayment"
  const { eventId, externalId } = getParameterIds()
  const items = order?.items || []
  const contents = items.map((item) => {
    return {
      content_id: item.sku_id ?? "",
      content_name: item.sku_name ?? "",
      price: item.price,
      quantity: 1,
    }
  })

  sendTikTokEvent(externalId, eventId, event, contents)
  trackTtqEvent(event, contents, eventId)
}
